body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.outer-container {
  z-index: 2147483646;
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: white; */
}

.inner-container {
  z-index: 99999;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.centered-content {
  z-index: 99999;
  display: inline-block;
  text-align: left;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  /* border-radius: 20px; */
  /* border: 1px solid whitesmoke; */
}
.inner-loading {
  text-align: center;
}

.text-loading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #e0e0e0;
  text-shadow: 2px 2px #27ae60;
}

/* .color-green{
  color: #27AE60;
} */

.menunggu {
  animation: dots 1s steps(5, end) infinite;
}

.close-button-tentang-kami {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -16px;
  top: -16px;
  cursor: pointer;
  z-index: 2;
}

.p-32px {
  padding: 32px !important;
}

.gap-30px {
  display: flex;
  gap: 30px;
}

.row-custom {
  display: flex;
  flex-direction: row;
}

.col-custom {
  display: flex;
  flex-direction: column;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
