.background {
   
    width: 100% !important;
    height: 100% !important;
    left: 0px;
    top: 0px;
}
.footer-background {
    background: #268D44;
    
}

.pd-80 {
    padding: 80px;
}

.email {
    font-size: small;
}

.pd-20 {
    padding: 20px;
}

.color-white {
    color : #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
}
.color-white2 {
    color : #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-size: 11px;
}

.bolder {
    font-weight: 600;
}
.border-hr {
    border: 2px solid white;
}
.ol-padding {
    padding-left: 10px;
}
.li-padding {
    padding-left: 12px;
}
.footer-footer-background{
    background: black;
}
.pd-96 {
    padding: 96px;
}


.linking:link {
    text-decoration: none;
}

.linking:visited {
text-decoration: none;
}

.linking:hover {
text-decoration: underline;
}

.linking:active {
text-decoration: underline;
}


.custom-modal-style {
    /* width: 897px; */
    height: 600px;
}

.footer-warning {
    padding: 0 ;
    display: flex !important ;
    justify-content: center !important ;
    align-items: center !important ;
    padding: 8px ;
    border-radius: 4px ;
    background: #FFFFFF1A;
    margin-top: 12px  !important ;
}

.footer-warning p {
    margin: 0 !important ;
    font-size: 12px ;
    text-align: center ;
    width: 100% ;
    color: #FFFFFF;
}

.pd-80-footer {
    padding: 80px;
    padding-bottom: 24px ;
}
