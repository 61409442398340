.body {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.twp {
  padding: 24px;
  background-color: #f1fcf4;
  border-radius: 4px;
  width: 100%;
}

.header-twp {
  font-size: 18px;
  font-weight: 500;
  color: #27ae60;
}

.hitungan-twp {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  font-size: 16px;
}

.modal-width {
  max-width: 660px !important;
}

.dot-header-active {
  background-color: #268d44;
}

.dot-header-nonactive {
  background-color: #dddddd;
}

.dot-header {
  height: 3px;
  width: 14px;
  border: 0;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 1px;
}

.dot-header:hover {
  background-color: #268d44;
}

.tkb-carousel {
  padding-bottom: 5px;
}

.dot-class-tkb {
  right: auto !important;
}

.logo {
  width: 105, 82px;
  height: 40px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  padding-left: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tittle-pinjaman {
  padding-top: 28px;
  padding-bottom: 28px;
  /* color : #268D44; */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.line {
  position: absolute;
  margin-bottom: 5px;
  margin-top: 1px;
  margin-left: 4.32rem;
}

.line-pendanaan {
  position: absolute;
  margin-bottom: 5px;
  margin-top: 1px;
  margin-left: 4rem;
}

.line-tentangkami {
  position: absolute;
  margin-bottom: 5px;
  margin-top: 1px;
  margin-left: 3.5rem;
}

.tittle-pendanaan {
  padding-top: 28px;
  padding-bottom: 28px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  position: static;
  height: 24px;
  left: 10.17%;
  right: 10.17%;
  top: calc(50% - 24px / 2);
  color: #555555;
}

.tittle-tetkami {
  padding-top: 28px;
  padding-bottom: 28px;
  font-family: Poppins;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  position: static;
  height: 24px;
  left: 10.17%;
  right: 10.17%;
  top: calc(50% - 24px / 2);
  color: #555555;
}

.tittle-tkb {
  padding-top: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 2rem !important;
}

.persentase {
  text-align: start;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #268d44;
}

.info-tkb {
  padding-top: 15px;
  padding-bottom: 40px;
  position: absolute;
  right: 112px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.fixed-row {
  top: 0 !important;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 999999999;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
}

.align-center-item {
  align-items: "center";
}

.text-right {
  text-align: right;
}

.padding-right-80 {
  padding-right: 80px !important;
}

.page-active {
  color: #268d44;
  font-weight: 600;
  display: inline-block;
  position: relative;
}

.page-active:after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #268d44;
  width: 70%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.blog-card {
  padding: 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
}

.box-item {
  box-shadow: 0px 1px 3px rgb(59 74 116 / 24%) !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}

.tkb-modal {
  position: absolute;
  max-width: 600px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  /* margin-left: 46rem; */
  z-index: 9999999999;
  /* float: right; */
  right: 10px;
}

.text-tkb-90 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
}

.text-bawah-tkb {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #555555;
}

.text-keterangan {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.segitiga {
  position: absolute;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  /* margin-left: 79.5rem; */
  margin-top: 0.2rem;
  right: 10rem;
  z-index: 9999999999;
}

.wa-button {
  position: fixed;
  right: 2%;
  bottom: 4%;
  cursor: pointer;
  width: 54px;
  height: 54px;
  background-color: #28af60;
  border-radius: 9px;
  box-shadow: 0px 2px 9px 0px #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wa-button:hover {
  background-color: #17683a;
}

/* @media screen and (max-width: 1920px) {
    .segitiga{
        margin-left: 70.9rem;    
    }

    .tkb-modal{
        margin-left: 40rem;
    }
} */
